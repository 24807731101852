import request from 'lib/request'
import axios from 'lib/request/axios'

const baseUrl = '/api/office'

const api = {
  // 포인트 지급요청(유저)
  create: (data) => request.post(`${baseUrl}/request`, data),

  // 포인트 리스트(관리자)
  fetch: (meta) => request.get(`${baseUrl}/requests`, meta),
  fetchOne: (id) => request.get(`${baseUrl}/requests/${id}`),
  update: (data) => request.post(`${baseUrl}/requests/${data.id}`, data),
  update_state: (id, state) =>
    request.post(`${baseUrl}/requests/${id}/state`, { state }),
  all: (meta) =>
    axios({ method: 'get', url: `${baseUrl}/requests/all`, params: meta }),
}

export default api
