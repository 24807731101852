import React, { useState } from 'react'
import { Button, Modal, Layout, Alert, Space } from 'antd'
import { Form, Input, Checkbox, Spin } from 'antd'
import { Row, Col } from 'antd'
import Privacy from './Privacy'
import { DownloadOutlined, HomeOutlined } from '@ant-design/icons'

const { Content, Footer, Header } = Layout

const initialRequest = {
  // nw_card: '',
  mobile: '',
  username: '',
  jumin_num: '',
}

// 교환중단 시 true
const isOpen = true
// 공지사항 노출 시 true
const isNotice = false

const PointRequestForm = (props) => {
  const {
    loading,
    errors,
    success,
    amount,
    removeError,
    errorMessage,
    goHome,
    goApp,
  } = props
  const { handleSubmit } = props
  const [inputs, setInputs] = useState(initialRequest)
  const [check, setCheck] = useState(false)
  const [open, setOpen] = useState(false)
  // 공지팝업(상품권정립 중단)
  const [popupOpen, setPopupOpen] = useState(true)
  const closePopup = () => setPopupOpen(false)

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    removeErrorWithName(errors, e.target.name)
  }

  const toggleChecked = () => {
    setCheck(!check)
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const removeErrorWithName = (errors, name) => {
    errors && errors[name] && removeError({ name })
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const onSubmit = () => {
    if (!inputs.mobile) {
      alert('휴대전화번호를 입력해주세요.')
      return false
    }
    if (!inputs.username) {
      alert('성함을 입력해주세요.')
      return false
    }
    if (!inputs.jumin_num) {
      alert('주민등록번호를 입력해주세요.')
      return false
    }

    if (!check) {
      alert('개인정보 처리방침에 동의하셔야 합니다.')
      return false
    }

    handleSubmit(inputs)
  }

  const successMessage = () => (
    <div style={{ marginTop: 30 }}>
      <p>
        적립내역은 모바일{' '}
        <span style={{ color: '#188FFF', fontWeight: 'bold' }}>
          지역상품권앱
        </span>
        으로 확인가능합니다.
      </p>

      <p>이용해 주셔서 감사합니다.</p>
    </div>
  )

  if (success) {
    return (
      <Layout>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <div>
            <div
              style={{ color: 'white', fontSize: '1.3em', textAlign: 'center' }}
            >
              사또요 적립금 지급 완료
            </div>
          </div>
        </Header>
        <Content style={{ margin: '16px 16px 16px' }}>
          <div
            className="site-layout-background"
            style={{ margin: '0 auto 50px', maxWidth: 500 }}
          >
            <Alert
              message={`${amount}원이 적립되었습니다.`}
              description={successMessage()}
              type="success"
              showIcon
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '100px',
            }}
          >
            <Space>
              <Button
                type="primary"
                onClick={() => goHome()}
                icon={<HomeOutlined />}
              >
                사또요
              </Button>
              <Button
                type="primary"
                onClick={() => goApp()}
                icon={<DownloadOutlined />}
              >
                지역상품권앱 chak
              </Button>
            </Space>
          </div>
        </Content>
        <Footer style={{ marginBottom: 200, textAlign: 'center' }}>
          <div></div>
        </Footer>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <div>
            <div
              style={{ color: 'white', fontSize: '1.3em', textAlign: 'center' }}
            >
              사또요 적립금 지급 신청
            </div>
          </div>
        </Header>
        <Content style={{ margin: '16px 16px 16px' }}>
          <Spin spinning={loading}>
            <Alert
              type="success"
              message="신청유의사항"
              description={
                <div>
                  <div>* 일적립한도는 1회입니다.</div>
                  <div>* 월적립한도는 20회입니다.</div>
                  <div>* 월별 상품권 신청한도는 20,000원입니다.</div>
                </div>
              }
            />
            <div style={{ marginTop: 30 }}></div>
            {/* {!isOpen && (
              <Alert
                type="error"
                message="상품권 업그레이드 작업안내"
                description={
                  <div>
                    <div>* 일정: 2023년 10월 16일 00시 ~ 18일 12시</div>
                    <div>* 내용: 조폐공사 상품권관련 업그레이드작업</div>
                    <div>* 해당 기간동안 상품권 신청이 제한됩니다.</div>
                    <div>* 18일 이후에 신청바랍니다.</div>
                  </div>
                }
              />
            )} */}
            {isNotice && (
              <Alert
                type="error"
                message="상품권 교환 중단 안내"
                description={
                  <div>
                    <div>
                      * 2024년 6월 현재 예산 소진으로 상품권 교환이 일시
                      중단되었습니다.
                    </div>
                    <div>
                      * 교환이 되지 않을 경우 2024년 6월 12일 이후에 다시 신청해
                      주십시오.
                    </div>
                  </div>
                }
              />
            )}
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 300,
                margin: '0 auto',
                maxWidth: 500,
              }}
            >
              {errorMessage && (
                <Alert
                  message={errorMessage}
                  type="error"
                  closable
                  style={{ marginBottom: '10px' }}
                />
              )}
              {/* TODO: reason에 따라서 버튼등 추가 reason === 'maximum' || reason === 'member'*/}
              <Form {...layout}>
                <Form.Item
                  label="휴대전화번호"
                  validateStatus={errors && errors.mobile && 'error'}
                  help={errors && errors.mobile && errors.mobile[0]}
                >
                  <Input
                    name="mobile"
                    value={inputs.mobile}
                    onChange={handleInputChange}
                    disabled={loading || !isOpen}
                    placeholder="숫자만 입력해주세요"
                  />
                </Form.Item>
                <Form.Item
                  label="이름"
                  validateStatus={errors && errors.username && 'error'}
                  help={errors && errors.username && errors.username[0]}
                >
                  <Input
                    name="username"
                    value={inputs.username}
                    onChange={handleInputChange}
                    disabled={loading || !isOpen}
                  />
                </Form.Item>
                <Form.Item
                  label="생년월일"
                  validateStatus={errors && errors.jumin_num && 'error'}
                  help={errors && errors.jumin_num && errors.jumin_num[0]}
                >
                  <Input
                    name="jumin_num"
                    value={inputs.jumin_num}
                    onChange={handleInputChange}
                    disabled={loading || !isOpen}
                    placeholder="19990101형태"
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    onChange={toggleChecked}
                    disabled={loading || !isOpen}
                  >
                    <span>개인정보 처리방침</span>에 동의합니다.
                  </Checkbox>
                  <br />
                  <div>
                    <Button type="link" onClick={toggleOpen}>
                      개인정보 처리방침
                    </Button>
                  </div>
                </Form.Item>
              </Form>
              <div style={{ marginTop: 40 }}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Button block onClick={goHome}>
                      취소
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      block
                      onClick={onSubmit}
                      // disabled={!isOpen}
                      disabled
                    >
                      신청
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <div></div>
        </Footer>
        <Modal
          title="개인정보 처리방침"
          visible={open}
          onOk={toggleOpen}
          onCancel={toggleOpen}
          footer={null}
        >
          <Privacy />
        </Modal>
        <Modal
          title=""
          visible={popupOpen}
          onCancel={closePopup}
          footer={null}
          className="popup1"
        >
          {/* <img src="/notice_popup.jpeg" width="100%" alt="popup" /> */}
          <img src="/notice240721.jpeg" width="100%" alt="popup" />
        </Modal>
      </Layout>
    )
  }
}

export default PointRequestForm
