import React from 'react'
import UnSavedPoint from './UnSavedPoint'

const CustomerPointAddForm = ({ plusid_id, ct_id, unSavedPoints, reload }) => {
  return (
    <div style={{ padding: '15px 10px' }}>
      {unSavedPoints.map((point) => (
        <UnSavedPoint
          point={point}
          key={point.id}
          plusid_id={plusid_id}
          ct_id={ct_id}
          reload={reload}
        />
      ))}
    </div>
  )
}

export default CustomerPointAddForm
