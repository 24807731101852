import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { AppLoader } from 'modules/core/app'
import { logout } from 'modules/core/account/store'
import history from 'lib/history'

// core modules routes
import { LoginPage } from 'modules/core/account'
import { PointsPage } from 'modules/points'
import {
  PointsRequestPage,
  PointsRequestListPage,
} from 'modules/points_requests'

// 유저 points
import { CustomerPointPage, CustomerPointRequestPage } from 'modules/customer'

// import { AgenciesPage } from 'modules/users/agencies'
// import { BranchesPage } from 'modules/users/branches'
// import { OfficesPage } from 'modules/users/offices'
import { DefaultLayout } from 'modules/core/layout'
// import { StoresPage } from 'modules/stores'
// import { NoticeEditContainer, NoticeListContainer, NoticeShowContainer } from 'modules/notices'
// import { StatsContainer } from 'modules/stats'
// import PaymentContainer from 'modules/payment/containers/PaymentContainer'
// import ChangeStoreAgency from 'modules/admin/ChangeStoreAgency'
// import AdminEctPage from 'modules/admin/AdminEctPage'
import { DashboardPage } from 'modules/pages'
import { GuestPage } from 'modules/pages'
import { PrivacyPage } from 'modules/points_requests'

const adminRoutes = [
  // { name: '대시보드', sidebar: true, path: '/', exact: true, component: DashboardPage },
  {
    name: '적립금 적립현황',
    sidebar: true,
    path: '/points',
    exact: true,
    component: PointsPage,
  },
  {
    name: '송금요청현황',
    sidebar: true,
    path: '/points/requests',
    exact: true,
    component: PointsRequestListPage,
  },
  // { name: '가맹점 관리', sidebar: true, path: '/stores', exact: false, component: StoresPage },
  // { name: '대리점 관리', sidebar: true, path: '/agencies', exact: true, component: AgenciesPage, role: ['server', 'admin', 'branche'] },
  // { name: '총판 관리', sidebar: true, path: '/branches', exact: true, component: BranchesPage, role: ['server', 'admin'] },
  // { name: '지자체 관리', sidebar: true, path: '/offices', exact: true, component: OfficesPage, role: ['server', 'admin'] },
  // { name: '공지사항', sidebar: true, path: '/notices', exact: true, component: NoticeListContainer },
  // { name: '공지사항-생성', sidebar: false, path: '/notices/edit', exact: true, component: NoticeEditContainer },
  // { name: '공지사항-수정', sidebar: false, path: '/notices/edit/:id', exact: true, component: NoticeEditContainer },
  // { name: '공지사항-상세', sidebar: false, path: '/notices/show/:id', exact: true, component: NoticeShowContainer },
  // { name: '등록현황', sidebar: true, path: '/stats', exact: true, component: StatsContainer, role: ['server', 'admin'] },
  // { name: '정산관리', sidebar: true, path: '/payments', exact: true, component: PaymentContainer, role: ['server', 'admin'] },
  // { name: '기타 관리', sidebar: true, path: '/admin/etc', exact: true, component: AdminEctPage, role: ['server', 'admin'] },
]

const Routes = (props) => {
  const loaded = useSelector((store) => store.app.loaded)
  const dispatch = useDispatch()
  const user = useSelector((store) => store.account.user)
  const handleLogout = () => {
    dispatch(logout())
    history.push('/login')
  }

  function getRoutes(routes) {
    return routes.map((route) => {
      if (route.routes) {
        return route.routes.map((sub) => (
          <Route
            key={sub.path}
            path={sub.path}
            exact={sub.exact}
            component={sub.component}
          />
        ))
      }

      return (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      )
    })
  }

  if (!loaded) return <AppLoader />

  return (
    <>
      <Switch>
        <Route
          path="/"
          exact
          render={() => <Redirect to="/points/request" />}
        />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/points/request" exact component={PointsRequestPage} />
        <Route path="/customers/points" exact component={CustomerPointPage} />
        <Route path="/privacy" exact component={PrivacyPage} />
        <Route
          path="/customers/points/request"
          exact
          component={CustomerPointRequestPage}
        />

        <DefaultLayout
          user={user}
          handleLogout={handleLogout}
          routes={adminRoutes}
        >
          {getRoutes(adminRoutes)}
        </DefaultLayout>
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </>
  )
}

export default Routes
