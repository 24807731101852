// replace 1. Points => Stores
// replace 2. POINTS => STORES
// replace 3. points => stores
import { makeAsyncActionsTypes, makeActionCreator, makeAsyncActionCreator } from 'lib/saga/reduxActionUtils'
import produce from 'immer'
/************************************************************************
 // 1. action types
 
 FETCH_REQUESTS.INDEX
 FETCH_REQUESTS.REQUEST
 FETCH_REQUESTS.SUCCESS
 FETCH_REQUESTS.FAIL
 NORMAL_ACTION
 ************************************************************************/
const prefix = '@APP/REQUESTS'

export const actionTypes = {
  FETCH_REQUESTS: makeAsyncActionsTypes(prefix, 'FETCH_REQUESTS'),
  CREATE_REQUEST: makeAsyncActionsTypes(prefix, 'CREATE_REQUEST'),
  UPDATE_REQUEST: makeAsyncActionsTypes(prefix, 'UPDATE_REQUEST'),
  FETCH_REQUEST: makeAsyncActionsTypes(prefix, 'FETCH_REQUEST'),

  CLOSE_MODAL: `${prefix}/CLOSE_MODAL`,
  OPEN_MODAL: `${prefix}/OPEN_MODAL`,
  REMOVE_ERROR: `${prefix}/REMOVE_ERROR`,
  REMOVE_REQUEST_ERROR: `${prefix}/REMOVE_REQUEST_ERROR`,
}

/************************************************************************
// 2. action creator

fetchPoints(payload)
fetchPoints.request(payload)
fetchPoints.success(payload)
fetchPoints.fail(payload)
resetUser(payload)
************************************************************************/
export const fetchRequests = makeAsyncActionCreator(actionTypes.FETCH_REQUESTS)
export const createRequest = makeAsyncActionCreator(actionTypes.CREATE_REQUEST)
export const updateRequest = makeAsyncActionCreator(actionTypes.UPDATE_REQUEST)
export const fetchRequest = makeAsyncActionCreator(actionTypes.FETCH_REQUEST)

export const closeModal = makeActionCreator(actionTypes.CLOSE_MODAL)
export const openModal = makeActionCreator(actionTypes.OPEN_MODAL)
export const removeError = makeActionCreator(actionTypes.REMOVE_ERROR)
export const removeRequestError = makeActionCreator(actionTypes.REMOVE_REQUEST_ERROR)
/***********************************************************************
// 3. reducer
error format
{
  status: 422,
  message: "입력값을 확인해주세요.",
  errors: [
    email: [{...}]
  ]
}

************************************************************************/
const initialModalState = {
  open: false,
  requesting: false,
  error: null,
  request: null,
}

const initialRequest = {
  requesting: false,
  error: null,
  success: false,
  amount: 0,
}

const initialState = {
  loading: false,
  error: null,
  requests: [],
  modal: initialModalState,
  meta: {
    total: 0,
    perPage: 10,
    currentPage: 1,
  },
  search: {},
  pagination: { total: 0, per_page: 10, current_page: 1 },
  request: initialRequest,
}

const requestsReducer = function (state = initialState, action) {
  switch (action.type) {
    // FETCH_REQUESTS
    case actionTypes.FETCH_REQUESTS.INDEX:
    case actionTypes.FETCH_REQUESTS.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_REQUESTS.SUCCESS:
      return {
        ...state,
        requests: action.payload.requests.data,
        meta: {
          total: action.payload.requests.total,
          per_page: action.payload.requests.per_page,
          current_page: action.payload.requests.current_page,
          page: action.payload.requests.current_page,
          ...action.payload.search,
        },
        pagination: {
          total: action.payload.requests.total,
          per_page: action.payload.requests.per_page,
          current_page: action.payload.requests.current_page,
        },
        search: {
          ...action.payload.search,
        },
        loading: false,
      }
    case actionTypes.FETCH_REQUESTS.FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          loading: false,
          error: action.payload.error,
        },
      }

    // FETCH_REQUEST
    case actionTypes.FETCH_REQUEST.INDEX:
    case actionTypes.FETCH_REQUEST.REQUEST:
      return { ...state, modal: { ...state.modal, requesting: true } }
    case actionTypes.FETCH_REQUEST.SUCCESS:
      return {
        ...state,
        modal: { ...state.modal, requesting: false, request: action.payload.request },
      }
    case actionTypes.FETCH_REQUEST.FAIL:
      return { ...state, modal: { ...state.modal, requesting: false, error: action.payload.error } }

    // CREATE_REQUEST
    case actionTypes.CREATE_REQUEST.INDEX:
    case actionTypes.CREATE_REQUEST.REQUEST:
      return { ...state, request: { ...state.request, requesting: true, error: null } }
    case actionTypes.CREATE_REQUEST.SUCCESS:
      return {
        ...state,
        request: { ...state.request, requesting: false, success: true, amount: action.payload.amount },
      }
    case actionTypes.CREATE_REQUEST.FAIL:
      return { ...state, request: { ...state.request, requesting: false, error: action.payload.error } }

    // REMOVE_REQUEST_ERROR
    case actionTypes.REMOVE_REQUEST_ERROR:
      return produce(state, (draft) => {
        draft.request.error.errors[action.payload.name] = undefined
        draft.request.error.message = undefined
      })

    // UPDATE_REQUEST
    case actionTypes.UPDATE_REQUEST.INDEX:
    case actionTypes.UPDATE_REQUEST.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.UPDATE_REQUEST.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false }, request: {} }
    case actionTypes.UPDATE_REQUEST.FAIL:
      return { ...state, modal: { ...state.modal, loading: false, error: action.payload.error } }

    // OPEN_MODAL
    case actionTypes.OPEN_MODAL:
      return { ...state, modal: { ...state.modal, open: true } }

    // CLOSE_MODAL
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        modal: initialModalState,
      }

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.modal.error.errors[action.payload.name] = undefined
        draft.modal.error.message = undefined
      })
    default:
      return state
  }
}

export default requestsReducer
