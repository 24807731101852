import { all } from 'redux-saga/effects'
import { sagas as coreSagas } from 'modules/core'
import pointsSagas from 'modules/points/sagas'
import requestsSagas from 'modules/points_requests/sagas'
import officesSagas from 'modules/users/offices/sagas'

export default function* rootSaga() {
  yield all([coreSagas(), pointsSagas(), requestsSagas(), officesSagas()])
  // yield all([coreSagas()])
}

// How to Handle Multiple Sagas in Redux Saga https://www.youtube.com/watch?v=fEjevUGH4BU
