import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects'
import { actionTypes } from './store'
import { fetchRequests, createRequest, fetchRequest, updateRequest, closeModal } from './store'
import api from './api'
import apiSaga from 'lib/saga/apiSaga'
import message from 'lib/message'

const { FETCH_REQUESTS, CREATE_REQUEST, FETCH_REQUEST, UPDATE_REQUEST } = actionTypes

// 1. request create
export function* createRequestSaga(action) {
  const success = yield call(apiSaga, api.create, createRequest, { apiPayload: action.payload })
  if (success) {
    // yield put(closeModal())
    message.success('신청 되었습니다.')
    // 페이지 이동
  } else {
    // message.error('입력내용을 확인해주세요')
  }
}

// 2. request fetch
export function* fetchRequestsSaga(action) {
  yield call(apiSaga, api.fetch, fetchRequests, { apiPayload: action.payload })
}

// 3. update
export function* updateRequestSaga(action) {
  const success = yield call(apiSaga, api.update, updateRequest, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('변경 되었습니다.')
    // 페이지 이동
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 4. fetchOne
export function* fetchRequestSaga(action) {
  yield call(apiSaga, api.fetchOne, fetchRequest, { apiPayload: action.payload.id })
}

export default function* pointsSagas() {
  yield all([
    takeLatest(CREATE_REQUEST.INDEX, createRequestSaga),
    takeLatest(FETCH_REQUESTS.INDEX, fetchRequestsSaga),
    takeLatest(UPDATE_REQUEST.INDEX, updateRequestSaga),
    takeLatest(FETCH_REQUEST.INDEX, fetchRequestSaga),
  ])
}
