import React from 'react'
import CustomerPoint from './CustomerPoint'

const CustomerPointList = ({ plusid_id, ct_id, points }) => {
  return (
    <div style={{ padding: '10px 15px', marginBottom: 30 }}>
      {points.map((point) => (
        <CustomerPoint point={point} key={point.id} />
      ))}
    </div>
  )
}

export default CustomerPointList
