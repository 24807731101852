import React, { useState } from 'react'
import { Form, Input, Select, Button, Space, ConfigProvider, DatePicker } from 'antd'
import moment from 'moment'
import 'moment/locale/ko'
import ko_KR from 'antd/lib/locale/ko_KR'
import { FileExcelOutlined } from '@ant-design/icons'

const dateFormat = 'YYYY-MM-DD'
const { Option } = Select

const initialSearch = { select: 'mobile', keyword: '', from: '', to: '', point_type: '적립' }

const PointSearch = ({ meta, handleSearch, handleExcelDownload, pagination, loading, downloading }) => {
  const [search, setSearch] = useState({ ...initialSearch, ...meta })

  const handlePointTypeChange = (value) => setSearch({ ...search, point_type: value })
  const handleSelectChange = (value) => setSearch({ ...search, select: value })
  const handleKeywordChange = (e) => setSearch({ ...search, keyword: e.target.value })
  const handleRangeChange = (dates, dateStrings) => {
    if (Array.isArray(dateStrings) && dateStrings.length === 2) {
      setSearch({ ...search, from: dateStrings[0], to: dateStrings[1] })
    }
  }

  const handleSubmit = () => {
    handleSearch({ ...search, page: 1 })
  }

  const handleReset = () => {
    setSearch(initialSearch)
    handleSearch({ ...initialSearch, currentPage: 1 })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Form layout="inline" style={{ marginBottom: 16 }}>
          <Form.Item style={{ width: 300 }}>
            <Input.Group compact>
              <Select style={{ width: '50%' }} defaultValue={search.select} onChange={handleSelectChange}>
                <Option value="mobile">휴대폰</Option>
                <Option value="phone">매장전화</Option>
                <Option value="store_name">매장상호</Option>
              </Select>
              <Input style={{ width: '50%' }} defaultValue={search.keyword} value={search.keyword} onChange={handleKeywordChange} />
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Select name="state" value={search.point_type} onChange={handlePointTypeChange} style={{ width: 150 }}>
              <Select.Option value="적립">적립</Select.Option>
              <Select.Option value="사용">사용</Select.Option>
              <Select.Option value="소멸">소멸</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <ConfigProvider locale={ko_KR}>
              <DatePicker.RangePicker
                format={dateFormat}
                onChange={handleRangeChange}
                value={[search.from && moment(search.from, dateFormat), search.to && moment(search.to, dateFormat)]}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={handleReset}>리셋</Button>
              <Button type="primary" onClick={handleSubmit}>
                검색
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      <div>
        <span style={{ fontWeight: 'bold', marginRight: 15 }}>
          <span style={{ color: 'gray' }}>Total: </span>
          {pagination && pagination.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
        <Button onClick={() => handleExcelDownload()} type="success" ghost style={{ marginBottom: 16 }} disabled={loading || downloading}>
          <FileExcelOutlined /> 엑셀
        </Button>
      </div>
    </div>
  )
}

export default PointSearch
