// replace 1. Offices => Stores
// replace 2. OFFICES => STORES
// replace 3. offices => stores
// replace 3. office => office
import { makeAsyncActionsTypes, makeActionCreator, makeAsyncActionCreator } from 'lib/saga/reduxActionUtils'
import produce from 'immer'
/************************************************************************
 // 1. action types
 
 FETCH_OFFICES.INDEX
 FETCH_OFFICES.REQUEST
 FETCH_OFFICES.SUCCESS
 FETCH_OFFICES.FAIL
 NORMAL_ACTION
 ************************************************************************/
const prefix = '@APP/OFFICES'

export const actionTypes = {
  FETCH_OFFICES: makeAsyncActionsTypes(prefix, 'FETCH_OFFICES'),
  FETCH_ONE_OFFICES: makeAsyncActionsTypes(prefix, 'FETCH_ONE_OFFICES'),
  CREATE_OFFICES: makeAsyncActionsTypes(prefix, 'CREATE_OFFICES'),
  UPDATE_OFFICES: makeAsyncActionsTypes(prefix, 'UPDATE_OFFICES'),
  DELETE_OFFICES: makeAsyncActionsTypes(prefix, 'DELETE_OFFICES'),
  CLOSE_MODAL: `${prefix}/CLOSE_MODAL`,
  OPEN_MODAL: `${prefix}/OPEN_MODAL`,
  REMOVE_ERROR: `${prefix}/REMOVE_ERROR`,
}

/************************************************************************
// 2. action creator

fetchOffices(payload)
fetchOffices.request(payload)
fetchOffices.success(payload)
fetchOffices.fail(payload)
resetUser(payload)
************************************************************************/
export const fetchOffices = makeAsyncActionCreator(actionTypes.FETCH_OFFICES)
export const fetchOneOffices = makeAsyncActionCreator(actionTypes.FETCH_ONE_OFFICES)
export const createOffices = makeAsyncActionCreator(actionTypes.CREATE_OFFICES)
export const updateOffices = makeAsyncActionCreator(actionTypes.UPDATE_OFFICES)
export const deleteOffices = makeAsyncActionCreator(actionTypes.DELETE_OFFICES)
export const closeModal = makeActionCreator(actionTypes.CLOSE_MODAL)
export const openModal = makeActionCreator(actionTypes.OPEN_MODAL)
export const removeError = makeActionCreator(actionTypes.REMOVE_ERROR)
/***********************************************************************
// 3. reducer
error format
{
  status: 422,
  message: "입력값을 확인해주세요.",
  errors: [
    email: [{...}]
  ]
}

************************************************************************/
const initialModalState = {
  open: false,
  requesting: false,
  error: null,
  office: null,
}

const initialState = {
  loading: false,
  error: null,
  offices: [],
  modal: initialModalState,
  meta: {
    total: 0,
    perPage: 10,
    currentPage: 1,
  },
}

const officesReducer = function (state = initialState, action) {
  switch (action.type) {
    // FETCH_OFFICES
    case actionTypes.FETCH_OFFICES.INDEX:
    case actionTypes.FETCH_OFFICES.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true
      })
    case actionTypes.FETCH_OFFICES.SUCCESS:
      return {
        ...state,
        offices: [...action.payload.offices],
        // offices: action.payload.offices,
        meta: action.payload.meta,
        loading: false,
      }
    case actionTypes.FETCH_OFFICES.FAIL:
      return { ...state, loading: false, error: action.payload.error }

    // FETCH_ONE_OFFICES
    case actionTypes.FETCH_ONE_OFFICES.INDEX:
    case actionTypes.FETCH_ONE_OFFICES.REQUEST:
      return { ...state, modal: { ...state.modal, requesting: true } }
    case actionTypes.FETCH_ONE_OFFICES.SUCCESS:
      return {
        ...state,
        modal: { ...state.modal, requesting: false, office: action.payload.office },
      }
    case actionTypes.FETCH_ONE_OFFICES.FAIL:
      return { ...state, modal: { ...state.modal, requesting: false, error: action.payload.error } }

    // CREATE_OFFICES
    case actionTypes.CREATE_OFFICES.INDEX:
    case actionTypes.CREATE_OFFICES.REQUEST:
      return { ...state, modal: { ...state.modal, open: true, requesting: true } }
    case actionTypes.CREATE_OFFICES.SUCCESS:
      return { ...state, modal: { ...state.modal, requesting: false } }
    case actionTypes.CREATE_OFFICES.FAIL:
      return {
        ...state,
        modal: { ...state.modal, requesting: false, error: action.payload.error },
      }

    // UPDATE_OFFICES
    case actionTypes.UPDATE_OFFICES.INDEX:
    case actionTypes.UPDATE_OFFICES.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.UPDATE_OFFICES.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false }, office: {} }
    case actionTypes.UPDATE_OFFICES.FAIL:
      return { ...state, modal: { ...state.modal, loading: false, error: action.payload.error } }

    // DELETE_OFFICES
    case actionTypes.DELETE_OFFICES.INDEX:
    case actionTypes.DELETE_OFFICES.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.DELETE_OFFICES.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false } }
    case actionTypes.DELETE_OFFICES.FAIL:
      return { ...state, modal: { ...state.modal, loading: false, error: action.payload.error } }

    // OPEN_MODAL
    case actionTypes.OPEN_MODAL:
      return { ...state, modal: { ...state.modal, open: true } }

    // CLOSE_MODAL
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        modal: initialModalState,
      }

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.modal.error.errors[action.payload.name] = undefined
      })
    default:
      return state
  }
}

export default officesReducer
