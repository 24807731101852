import { combineReducers } from 'redux'
import { reducers as coreReducers } from 'modules/core'
import points from 'modules/points/store'
import requests from 'modules/points_requests/store'

// import stores from 'modules/stores/store'
import offices from 'modules/users/offices/store'

const rootReducer = combineReducers({
  ...coreReducers,
  points,
  requests,
  offices,
  // stores,
})

export default rootReducer
