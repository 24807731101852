import React from 'react'
import { connect } from 'react-redux'
import { login } from '../../store'
import { Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import LoginComponent from '../../components/Login'

const LoginPage = (props) => {
  const { isAuthenticated, loading, error, handleSubmit } = props
  const isMobile = useMediaQuery({ maxWidth: 767 })

  if (isAuthenticated) return <Redirect to="/points" />

  return <LoginComponent {...{ handleSubmit, loading, error, isMobile }} />
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.account.user,
  loading: state.account.login.loading,
  error: state.account.login.loading,
})

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (email, password, remember) => dispatch(login(email, password, remember)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
