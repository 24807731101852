import React, { useEffect, useState } from 'react'
import { Table, Button, Popover, Space } from 'antd'
// import format from 'lib/format'
import { EditOutlined, FileExcelOutlined } from '@ant-design/icons'
import { render } from '@testing-library/react'
// import _ from 'lodash'
import api from '../../api'
import message from 'lib/message'

const fixedSizeText = (text, size = 20) => {
  if (!text) return text
  if (text.length > size) return text.substr(0, size - 2) + '...'
  return text
}

const displayState = (state, request) => {
  if (state === 'requesting')
    return <span style={{ color: 'blue', fontWeight: 'bold' }}>접수</span>
  if (state === 'success')
    return <span style={{ color: 'green' }}>{request.paid_at}</span>
  if (state === 'fail') return <span style={{ color: 'red' }}>거절</span>
}

const PointRequestList = ({
  loading,
  user,
  data,
  meta,
  pagination,
  handleOpen,
  handleAdd,
  handlePageChange,
  forceLogin,
  openPasswordModal,
  downloading,
  handleExcelDownload,
  load,
}) => {
  const [requests, setRequests] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setRequests(data)
  }, [data])

  const defaultColumns = [
    { title: '휴대폰', dataIndex: 'mobile', key: 'mobile', align: 'center' },
    { title: '이름', dataIndex: 'username', key: 'username', align: 'center' },
    {
      title: '생년월일',
      dataIndex: 'jumin_num',
      key: 'jumin_num',
      align: 'center',
    },
    // { title: '카드번호', dataIndex: 'nw_card', key: 'nw_card', align: 'center' },
    {
      title: '요청금액',
      dataIndex: 'request_point',
      key: 'request_point',
      align: 'center',
      render: (point) => point.toLocaleString(),
    },
    {
      title: '요청일',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
    },
    {
      title: '처리상태(지급일)',
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      render: (state, request) => displayState(state, request),
    },
    {
      title: '관리',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (memo, request) => (
        <Popover
          content={
            <Space>
              <Button
                type="default"
                onClick={() => handleChangeState(request.id, 'requesting')}
              >
                접수
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => handleChangeState(request.id, 'fail')}
              >
                거절
              </Button>
              <Button
                type="primary"
                onClick={() => handleChangeState(request.id, 'success')}
              >
                완료
              </Button>
            </Space>
          }
          title="상태변경"
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <Button type="primary" danger>
            상태변경
          </Button>
        </Popover>
      ),
    },
  ]

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const handleChangeState = (id, state) => {
    setOpen(false)
    api
      .update_state(id, state)
      .then((res) => {
        message.success('변경되었습니다.')
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        load()
      })
  }

  return (
    <div>
      <Table
        loading={loading}
        className="requestsTable"
        columns={defaultColumns}
        dataSource={requests}
        rowKey={(request) => request.id}
        pagination={pagination}
        bordered
      />
    </div>
  )
}

export default PointRequestList
