import request from 'lib/request'

const baseUrl = '/api/offices'

const api = {
  fetch: (meta) => request.get(baseUrl, meta),
  fetchOne: (user) => request.get(`${baseUrl}/${user.id}`),
  create: (user) => request.post(baseUrl, user),
  update: (user) => request.put(`${baseUrl}/${user.id}`, user),
  delete: (user) => request.del(`${baseUrl}/${user.id}`),
}

export default api
