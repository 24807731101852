import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPoints } from '../../store'
import queryString from 'query-string'
import api from '../../api'
import xlsx from 'xlsx'
import { format } from 'date-fns'
import message from 'lib/message'

import Page from 'components/Common/Page'
import PointSearch from '../../components/PointSearch'
import PointList from '../../components/PointList'

const PointsPage = (props) => {
  const { history, location } = props

  const dispatch = useDispatch()
  const loading = useSelector((store) => !!store.points.loading)
  const points = useSelector((store) => store.points.points)
  const meta = useSelector((store) => store.points.meta)
  const search = useSelector((store) => store.points.search)
  const pagination = useSelector((store) => store.points.pagination)

  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    const _query = queryString.parse(location.search)
    dispatch(fetchPoints(_query))
  }, [dispatch, location.search])

  const handlePageChange = (page) => {
    const _query = { ...queryString.parse(location.search), page: page }
    history.push(`/points?${queryString.stringify(_query)}`)
  }
  const handleSearch = (_search) => {
    history.push(`/points?${queryString.stringify(_search)}`)
  }

  const handleExcelDownload = () => {
    setDownloading(true)
    api
      .all(search)
      .then((response) => {
        const now = new Date()
        const timestamp = +now
        const filename =
          '사또요적립' + format(now, 'yyyymmdd') + timestamp + '.xlsx'
        // console.log(response.data)
        const data = convertToExcelData(response.data.points)
        downloadExcel(data.requests, filename, data.header)
      })
      .catch((error) => {
        message.error(`다운로드에 실패했습니다.`)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  return (
    <Page>
      <PointSearch
        meta={search}
        handleSearch={handleSearch}
        pagination={pagination}
        handleExcelDownload={handleExcelDownload}
        downloading={downloading}
        loading={loading}
      />
      {/* <PointSearch meta={search} handleSearch={handleSearch} pagination={pagination} /> */}
      <PointList
        loading={loading}
        meta={meta}
        data={points}
        pagination={{
          current: pagination.current_page,
          pageSize: pagination.per_page,
          total: pagination.total,
          onChange: handlePageChange,
          showSizeChanger: false,
        }}
      />
    </Page>
  )
}

export default PointsPage

function convertToExcelData(requests) {
  const header = [
    'No',
    '휴대폰번호',
    '변동타입',
    '내용/상호',
    '매장전화',
    '변동',
    '만료일',
    '고객포인트',
    '생성일',
  ]

  const arr = requests.map((request, index) => {
    return {
      no: index + 1,
      mobile: request.mobile,
      point_type: request.point_type,
      content: request.content,
      phone: request.phone,
      point: request.point,
      expire_date: request.expire_date,
      user_point: request.user_point,
      created_at: request.created_at,
    }
  })

  return {
    header: header,
    requests: arr,
  }
}

function downloadExcel(arr, filename = 'Test.xlsx', header = false) {
  const ws = xlsx.utils.json_to_sheet(arr)
  const wb = xlsx.utils.book_new()
  if (header) {
    header.forEach((x, idx) => {
      const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 })
      ws[cellAdd].v = x
    })
  }
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')
  xlsx.writeFile(wb, filename)
}
