import React from 'react'
import { Button, Modal, Layout, Alert, Space, Row, Col } from 'antd'

const CustomerPoint = ({ point }) => {
  return (
    <div
      style={{
        fontSize: 18,
        padding: '15px 0',
        borderBottom: '1px solid lightgray',
      }}
    >
      <Row>
        <Col flex="auto">
          {point.store_url ? (
            <a href={point.store_url}>{point.content}</a>
          ) : (
            point.content
          )}
        </Col>
        <Col flex="none">
          <div style={{ color: point.point < 0 ? 'red' : 'default' }}>
            {point.point.toLocaleString()}원 {point.point_type}
          </div>
        </Col>
      </Row>
      <div style={{ fontSize: 14, color: '#848484' }}>
        {point.created_at}{' '}
        {point.point > 0 &&
          point.point_type === '적립' &&
          `(만료일 : ${point.expire_date})`}
      </div>
    </div>
  )
}

export default CustomerPoint
