import React, { useState, useEffect } from 'react'
import { Button, Modal, Alert } from 'antd'
import { Form, Input, Radio, Row, Col } from 'antd'
import format from 'lib/format'

const PointRequestModal = (props) => {
  const { requesting, errors, request, removeError, errorMessage } = props
  const { open, handleClose, handleExit, handleUpdate } = props
  const [inputs, setInputs] = useState({})

  useEffect(() => {
    request && request.id && setInputs(request)
  }, [request])

  const handleOnUpdate = () => handleUpdate(inputs)

  const footer = [
    <Button key="cancle" onClick={handleClose} disabled={requesting}>
      취소
    </Button>,
    <Button key="update" type="primary" onClick={handleOnUpdate} disabled={requesting}>
      확인
    </Button>,
  ]

  const layout = {
    layout: 'horizontal',
  }

  const handleStateChange = (e) => setInputs({ ...inputs, state: e.target.value })

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    removeErrorWithName(errors, e.target.name)
  }

  const removeErrorWithName = (errors, name) => {
    errors && errors[name] && removeError({ name })
  }

  return (
    <Modal title="요청 처리" visible={open} afterClose={handleExit} onCancel={handleClose} footer={footer} maskClosable={false}>
      {!requesting && (
        <Form {...layout}>
          <div style={{ marginBottom: 20, padding: 20, backgroundColor: '#f0f2f5' }}>
            <Row>
              <Col span={8}>요청시각</Col>
              <Col span={16}>{inputs.created_at}</Col>
            </Row>
            <Row>
              <Col span={8}>휴대폰</Col>
              <Col span={16} style={{ fontWeight: 'bold' }}>
                {format.phone(inputs.mobile)}
                {/* <a href="#" style={{ fontWeight: 'normal', fontSize: '0.8rem', paddingLeft: 5 }}>
                  적립내역
                </a> */}
              </Col>
            </Row>
            <Row>
              <Col span={8}>이름</Col>
              <Col span={16} style={{ fontWeight: 'bold' }}>
                {inputs.username}
              </Col>
            </Row>
            <Row>
              <Col span={8}>생년월일</Col>
              <Col span={16} style={{ fontWeight: 'bold' }}>
                {inputs.jumin_num}
              </Col>
            </Row>
            {/* <Row>
              <Col span={8}>카드번호</Col>
              <Col span={16} style={{ fontWeight: 'bold' }}>
                {inputs.nw_card}
              </Col>
            </Row> */}
            <Row>
              <Col span={8}>요청금액</Col>
              <Col span={16} style={{ fontWeight: 'bold' }}>
                {inputs.request_point}
              </Col>
            </Row>
          </div>
          <Form.Item label="처리상태" validateStatus={errors && errors.state && 'error'} help={errors && errors.state && errors.state[0]}>
            <Radio.Group name="state" onChange={handleStateChange} value={inputs.state}>
              <Radio value="requesting">접수</Radio>
              <Radio value="success">지급</Radio>
              <Radio value="fail">거절</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="거절사유" validateStatus={errors && errors.memo && 'error'} help={errors && errors.memo && errors.memo[0]}>
            <Input name="memo" value={inputs.memo} onChange={handleInputChange} disabled={requesting} placeholder="" />
          </Form.Item>
          {errorMessage && <Alert message={errorMessage} type="error" closable />}
        </Form>
      )}
    </Modal>
  )
}

export default PointRequestModal
