import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Layout, Menu, Button } from 'antd'
import history from 'lib/history'

const { SubMenu } = Menu

const { Sider } = Layout

const getSubMenu = (route) => {
  // console.log(route)
  if (!route.sidebar) return null
  return (
    <SubMenu key={route.path} icon={route.icon} title={route.name}>
      {route.routes.map((sub) => getMenu(sub))}
    </SubMenu>
  )
}

const getMenu = (route) => {
  if (!route.sidebar) return null
  return (
    <Menu.Item key={route.path} icon={route.icon}>
      <Link to={route.path}>{route.name}</Link>
    </Menu.Item>
  )
}

const AdminLayout = ({ isAuthenticated, children, handleLogout, routes }) => {
  const [collapsed, setCollapsed] = useState(false)

  const onCollapse = () => setCollapsed(!collapsed)

  if (!isAuthenticated) return <Redirect to="/login" />

  return (
    <Layout style={{ minHeight: '100vh' }} id="admin-layout">
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }}>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={[history.location.pathname]} defaultOpenKeys={['/delivery']} mode="inline">
          {routes.map((route) => (route.routes ? getSubMenu(route) : getMenu(route)))}
          <Menu.Item>
            <Button type="link" onClick={handleLogout} style={{ color: 'yellow' }}>
              로그아웃
            </Button>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200 }}>
        {children}
      </Layout>
    </Layout>
  )
}
export default AdminLayout
