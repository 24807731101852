import React from 'react'

const Privacy = () => (
  <div style={{ fontSize: 'small', whiteSpace: 'pre-wrap' }}>
    {`
사또요는 이용자의 개인정보를 안전하게 관리하고 있으며, 이용자가 언제든지 쉽게 확인할 수 있도록 개인정보 처리방침을 공개하고 있습니다.

1. 개인정보의 수집 ∙ 이용

2. 개인정보 보유 ∙ 이용 기간

3. 개인정보처리의 위탁

4. 개인정보 제3자 제공

5. 이용자 및 법정대리인의 권리 ∙ 의무 및 행사방법

6. 아동의 개인정보보호

7. 개인정보 자동 수집 장치의 설치 ∙ 운영 및 거부

8. 개인정보의 파기

9. 개인정보의 안전성 확보조치

10. 링크 사이트

11. 의견수렴 및 불만처리

12. 개인정보보호책임자

13. 고지의 의무

1. 개인정보의 수집 ∙ 이용

① 사또요가 제공하는 서비스는 별도의 회원가입 절차 없이 자유롭게 컨텐츠에 접근할 수 있습니다. 사또요의 회원제 서비스 이용을 위해 수집 ∙ 이용하는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 목적 변경 시 별도의 동의를 받는 등 필요한 조치를 이행합니다.

구분수집 ∙ 이용 목적수집 ∙ 이용항목회원 가입회원 가입의사 확인, 회원제 서비스 이용을 위한 제한적 본인확인, 불만 처리 등 원활한 의사소통 경로의 확보, 서비스 부정이용 방지, 계약이행을 위한 각종 고지 ∙ 통지 ∙ 안내, 통계 ∙ 분석[e-mail 가입]
(필수) 이메일 주소, 비밀번호, 휴대전화번호
(선택) 닉네임[SNS 회원가입 – 네이버]
(필수) 이메일 주소, 이용자 식별값, 휴대전화번호
(선택) 별명, 생일, 성별, 연령대[SNS 회원가입 – 카카오톡]
(필수) 이메일 주소, 이용자 식별값, 휴대전화번호
(선택) 닉네임, 생일, 연령대, 성별[SNS 회원가입 – Apple]
(필수) 이메일 주소, 이용자 식별값, 휴대전화번호, 이름주문주문 관리, 배달 주소 및 연락처 확인, 민원 응대, 서비스 이용에 대한 리뷰 작성 권유, 서비스 이용 만족도 조사(필수) 수령인 정보(휴대전화번호, 주소)
* 포장 주문 시 주소 제외본인인증주문/쿠폰/멤버십(슈퍼클럽) 등 서비스 제공을 위한 이용자 본인확인, 주류구매 성인인증, 서비스 부정이용 방지[휴대전화 본인인증기관으로부터 제공받는 정보]
(필수) 휴대전화번호, DI(중복가입확인정보), 생년월일, 성별, 내/외국인 여부결제결제 수단 등록 및 관리, 결제 처리 결과 확인[결제대행업체(PG사)로부터 제공받는 정보]
(필수) 결제 처리 결과, 부분 마스킹 처리된 카드/계좌번호, 카드사, 은행명주변 맛집 검색배달 주소 설정 및 주변 맛집 검색 서비스 제공(선택) 개인위치정보선물하기선물하기 서비스 제공, 현금영수증 신청 정보 전달- 구매 시: (필수) 구매자/수신자 정보(성명, 휴대전화번호)
- 사용 시: (선택) 현금영수증 신청 정보(휴대전화번호, 사업자등록번호)
  * 현금영수증 발행 업체에 전달 후 서버에는 별도 저장하지 않음식품 이물 발견 신고배달 앱 업체 이물 통보 대리 신고(필수) 성명, 연락처, 주소상담 ∙ 민원1대1 고객 상담, 상담 ∙ 불만 ∙ 분쟁 처리시 개인 식별, 후속 응대 및 처리- 콜센터 이용 시: (필수) 발신자번호, 상담내용(녹취 포함), 필요 시 본인확인을 위한 정보(휴대전화번호, 이메일 주소)
- 상담톡/챗봇 이용 시: (필수) 카카오톡 이용자 식별값, 채팅내용, 필요 시 본인확인을 위한 정보(휴대전화번호, 이메일 주소)자동 생성 정보서비스 부정이용 방지, 개인정보유효기간제 준수, 맞춤형 서비스 제공, 온라인 맞춤형 광고(필수) 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 주소, 기기정보(모델명, OS 버전, 기기고유번호), 광고ID마케팅 ∙ 광고이벤트 및 혜택 알림 정보 등 마케팅 ∙ 광고 활용, 서비스/신상품이나 이벤트 정보 안내, 이벤트 경품 배송(선택) 이메일 주소, 휴대전화번호, 주소
* 사또요는 수집된 휴대전화번호 및 이메일을 이용하여 광고성 메시지를 전송할 수 있으며, 이를 원하지 않을경우 수신거부를 할 수 있습니다.

② 개인정보 수집 방법

a. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다.

b. 고객센터를 통한 상담 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.

c. 사또요와 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 제휴사에서 이용자에게 개인정보 제공 동의를 받은 후 수집합니다.

2. 개인정보 보유 ∙ 이용 기간

① 회사는 이용자로부터 개인정보 수집 시에 동의 받은 보유 ∙ 이용기간 내에서 개인정보를 처리 ∙ 보유합니다.

a. 회원 정보: 회원탈퇴 후 90 일까지(단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정에 따라 거래 관계 확인을 위한 소비자 식별정보(휴대전화번호, DI(중복가입확인정보))는 5년 간 보관 후 파기)
* 회원 탈퇴 또는 회원 자격 정지 후 회원 재가입, 임의 해지 등을 반복적으로 행하여 회사가 제공하는 할인쿠폰, 이벤트 혜택 등으로 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 등 편법 행위 또는 불법행위를 하는 회원을 차단하고자 회사는 회원 탈퇴 후 90 일 간 회원의 정보를 보유합니다.

b. 식품 이물 발견 신고: 이물 통보 완료 시까지

c. 법령 위반에 따른 수사 ∙ 조사 등이 진행중인 경우에는 해당 건 종료 시까지

d. 서비스 이용에 따른 채권 ∙ 채무관계 정산 시까지

② 1 년 간 회원의 서비스 이용 기록이 없는 경우, 『개인정보 보호법』 제39조의6에 근거하여 회원에게 사전 통지하고 휴면계정으로 전환합니다. 또한, 휴면계정의 개인정보는 다른 이용자의 개인정보와 분리하여 5년 간 별도 저장, 관리 됩니다.
단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정에 따라 거래 관계 확인을 위해 개인정보를 일정기간 보유합니다.

법령항목기간전자상거래 등에서의 소비자보호에 관한 법률계약 또는 청약철회 등에 관한 기록5년대금결제 및 재화 등의 공급에 관한 기록5년소비자의 불만 또는 분쟁 처리에 관한 기록3년위치정보의 보호 및 이용 등에 관한 법률개인위치정보에 관한 기록6개월전자금융거래법전자금융 거래에 관한 기록5년통신비밀보호법서비스 이용 관련 개인정보(로그기록)3개월

3. 개인정보처리의 위탁

① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.

수탁 업체위탁업무 ㈜카카오 비즈메시지 및 문자 서비스 운영

③ 사또요는 위탁계약 체결 시 위탁업무 수행 목적 외 개인정보 처리 금지, 기술적 ∙ 관리적 보호조치, 재 위탁 제한, 수탁자에 대한 관리 ∙ 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.

④ 위탁 업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

4. 개인정보 제3자 제공 및 공유

① 회사는 이용자의 개인정보를 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.

② 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.

a. 음식점개인정보를 제공받는 자제휴 음식점(리스트)제공받는 자의 개인정보 이용 목적주문 관리, 음식 배달 서비스 제공, 민원 응대제공하는 개인정보의 항목주소, 휴대전화번호(안심번호 적용 시 제외), 주문내역, 요청사항개인정보 보유 및 이용기간48시간b. 배달 대행(음식점 자체 배달 시 제외)개인정보를 제공받는 자제휴 배달 대행 업체(리스트), 제공받는 자의 개인정보 이용 목적음식 배달 서비스 제공제공하는 개인정보의 항목주소, 휴대전화번호, 주문내역, 요청사항개인정보 보유 및 이용기간제공 목적 달성 후 지체없이 파기(단, 관련 법령에 따라 보관이 필요한 경우 해당기간까지 보관)c. 공받는 자의 개인정보 이용 목적요마트 주문 관리, 상품 배달 서비스 제공, 영수증 발급제공하는 개인정보의 항목주소, 휴대전화번호(안심번호 적용 시 제외), 이메일 주소(영수증 발급 시), 주문내역, 요청사항개인정보 보유 및 이용기간제공 목적 달성 후 지체없이 파기(단, 관련 법령에 따라 보관이 필요한 경우 해당기간까지 보관)

③ 배달서비스 등 제휴서비스 제공을 위하여 이용자 동의 후 본조 제2항 기재 제3자에게 개인정보를 제공합니다.

④ 회사 서비스의 특성 상 수시로 발생하는 서비스 제공업체와의 제휴로 인하여 “제공 받는 자” 관련 개인정보 처리방침 개정이 어려우므로, 서비스 제공 업체를 위 표 내 제휴사 리스트 부분에 링크하여 안내합니다.

5. 이용자 및 법정대리인의 권리 ∙ 의무 및 행사방법

① 이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.

a. 개인정보 열람 요구

b. 오류 등이 있을 경우 정정 요구

c. 삭제 요구

d. 처리 정지 요구

② 위 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 내부 절차에 따라 영업일 10 일 이내 처리하도록 하겠습니다. (단, 관련 법령에 따라 일정기간 보관이 필요한 정보는 제외)

③ 이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.

④ 위 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우, 법정대리인은 이용자의 모든 권리를 가집니다.

⑤ 이용자는 정보통신망법, 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는 이용자 본인이나 타인의 개인정보 및 사생활을 침해하여서는 안됩니다.

⑥ 이용자의 개인정보 열람, 정정, 탈퇴는   언제든 가능합니다.

⑦ 회원탈퇴는  『회원탈퇴』를 클릭하거나 개인정보보호책임자에게 팩스, 우편, 고객센터, 전화 등으로 연락하시면 회원탈퇴 신청 시점으로부터 90 일 동안 재가입 방지를 위한 개인정보 보유 이후 개인정보의 삭제 등 필요한 조치를 하겠습니다.

⑧ 이용자는 개인정보를 최신의 상태로 정확하게 입력 또는 회사에 통보하여 불의의 사고가 발생하지 않도록 예방해야 합니다.

⑨ 이용자가 제공 또는 입력한 정보가 부정확함으로 인해 발생하는 사고의 책임은 이용자에게 있으며, 타인 정보의 도용 등 허위정보를 입력할 경우 사또요서비스 제공이 중단될 수 있습니다.

⑩ 이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무를 가지고 있으며, 이용자의 개인정보가 유출되지 않도록 조심하고, 타인의 개인정보를 훼손하지 않도록 유의해야 합니다. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」 등에 의해 처벌받을 수 있습니다.

6. 아동의 개인정보보호

회사는 만 8세 미만 아동의 개인정보를 보호하기 위하여, 만 8세 미만 아동의 회원가입은 제한합니다.

7. 개인정보 자동 수집 장치의 설치 ∙ 운영 및 거부

①  이용자의 서비스 개인식별정보와는 결합하여 이용되지 않습니다. 

8. 개인정보의 파기

①  개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 개인정보를 파기합니다.

② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.

③ 회사는 전자적 파일 형태로 기록 ∙ 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록 ∙ 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

9. 개인정보의 안전성 확보조치

①  이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 보호조치를 이행하고 있습니다.

② 기술적 보호조치

a. 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금 기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.

b. 사또요는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.

c. 사또요는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.

d. 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.

③ 관리적 보호조치

a. 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.

- 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자

- 고객의 불만 및 이용문의 처리 업무를 수행하는 자

- 개인정보보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자

- 기타 업무상 개인정보의 취급이 불가피한 자

b. 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보 처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.

c. 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.

d. 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.

e. 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.

10. 링크 사이트

회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보 처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.

11. 의견수렴 및 불만처리

① 회사는 이용자의 의견을 소중하게 생각하며, 이용자는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.

② 회사는 이용자와의 원활한 의사소통을 위해 고객센터를 운영하고 있으며 연락처는 다음과 같습니다.

메일:apt7766@naver.com 

③ 전자우편을 이용한 상담은 접수 후 성실하게 답변 드리겠습니다. 다만, 근무시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.


12. 개인정보보호책임자

① 회사는 이용자의 개인정보를 보호하는데 있어 안전하게 보호될 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보보호책임자가 모든 책임을 집니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다.

② 이용자의 개인정보를 취급하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.
메일:apt7766@naver.com 

13. 고지의 의무

현 개인정보 처리방침은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전(단, 중요한 내용 변경의 경우 30일 전)부터 회사가 운영 하는 사이트의 '공지'란 또는 이메일을 통해 고지할 것입니다.

- 고지 일자: 2021.7.1.

- 시행 일자: 2021.7.1.
`}
  </div>
)

export default Privacy
