import React, { useState } from 'react'

import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

const Login = ({ handleSubmit, loading, error, links, isMobile }) => {
  const [values, setValues] = useState({
    loginId: '',
    password: '',
    remember: false,
  })

  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value, e.target.checked)
    setValues({
      ...values,
      [e.target.name]:
        e.target.name === 'remember' ? e.target.checked : e.target.value,
    })
  }

  const onSubmit = () => {
    if (values.loginId && values.password) handleSubmit(values)
    else alert('아이디와 비밀번호를 입력해주세요')
  }

  return (
    <div
      style={{
        maxWidth: 360,
        margin: '100px auto 0',
        padding: 20,
        marginTop: isMobile ? 10 : 100,
      }}
    >
      <div style={{ marginBottom: 30, textAlign: 'center' }}>
        <span style={{ fontSize: 32, fontWeight: 'bold', color: '#1890ff' }}>
          사또요 적립금 관리
        </span>
      </div>
      <Form className="login-form">
        <Form.Item
          name="loginId"
          rules={[{ required: true, message: '아이디를 입력해주세요.' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            name="loginId"
            type="text"
            placeholder="아이디"
            onChange={handleChange}
            value={values.loginId}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="password"
            value={values.password}
            onChange={handleChange}
            type="password"
            placeholder="비밀번호"
            size="large"
          />
        </Form.Item>
        {/* <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox name="remember" value={values.remember} onChange={handleChange}>
              기억하기
            </Checkbox>
          </Form.Item>
        </Form.Item> */}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            onClick={onSubmit}
            size="large"
            block
          >
            로그인
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Login
