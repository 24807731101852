import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import format from 'lib/format'

const PointList = ({ loading, data, meta, pagination, handleOpen, handleAdd, handlePageChange }) => {
  const [points, setPoints] = useState([])

  useEffect(() => {
    setPoints(data.map((point) => ({ ...point, isActive: false })))
  }, [data])

  const defaultColumns = [
    { title: '휴대폰', dataIndex: 'mobile', key: 'mobile', align: 'center' },
    {
      title: '내용',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone, point) => {
        return (
          <span>
            {point.content} {phone && '(' + phone + ')'}
          </span>
        )
      },
    },
    { title: '변동', dataIndex: 'point', key: 'point', align: 'center' },
    { title: '사용', dataIndex: 'used_point', key: 'used_point', align: 'center' },
    { title: '만료', dataIndex: 'expired', key: 'expired', align: 'center' },
    { title: '만료일', dataIndex: 'expire_date', key: 'expire_date', align: 'center' },
    { title: '고객포인트', dataIndex: 'user_point', key: 'user_point', align: 'center' },
    { title: '변동타입', dataIndex: 'point_type', key: 'point_type', align: 'center' },
    { title: '생성일', dataIndex: 'created_at', key: 'created_at', align: 'center' },
  ]

  return (
    <div>
      <Table
        loading={loading}
        className="pointsTable"
        columns={defaultColumns}
        dataSource={points}
        rowKey={(point) => point.id}
        pagination={pagination}
        bordered
        rowClassName={(point) => (point.isActive ? 'active-point' : '')}
      />
    </div>
  )
}

export default PointList
