import React from 'react'

const CustomerPointRequestPage = (props) => {
  return (
    <div>
      <div>CustomerPointRequestPage</div>
    </div>
  )
}

export default CustomerPointRequestPage
