import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import api from 'modules/core/account/api'
import { setToken } from 'lib/token'
import message from 'lib/message'
import { createRequest, removeRequestError } from '../../store'
import queryString from 'query-string'

import PointRequestForm from '../../components/PointRequestForm'

const PointsRequestPage = (props) => {
  const { history } = props

  const dispatch = useDispatch()

  const loading = useSelector((store) => !!store.requests.request.requesting)
  const success = useSelector((store) => !!store.requests.request.success)
  const errors = useSelector((store) => store.requests.request.error && store.requests.request.error.errors)
  const amount = useSelector((store) => store.requests.request.amount)

  // 400 에러일때
  const errorMessage = useSelector(
    (store) => store.requests.request.error && store.requests.request.error.status === 400 && store.requests.request.error.message
  )

  const handleSubmit = (inputs) => {
    dispatch(createRequest(inputs))
    // console.log(inputs)
    // alert('신청되었습니다.')
  }

  const goHome = () => {
    window.location.href = process.env.REACT_APP_HOME_URL
  }

  const goApp = () => {
    window.location.href = process.env.REACT_APP_APP_URL
  }

  return (
    <PointRequestForm
      loading={loading}
      success={success}
      amount={amount}
      errors={errors}
      errorMessage={errorMessage}
      handleSubmit={handleSubmit}
      goHome={goHome}
      goApp={goApp}
      removeError={(payload) => dispatch(removeRequestError(payload))}
    />
  )
}

export default PointsRequestPage
