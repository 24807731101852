// replace 1. Points => Stores
// replace 2. POINTS => STORES
// replace 3. points => stores
import { makeAsyncActionsTypes, makeActionCreator, makeAsyncActionCreator } from 'lib/saga/reduxActionUtils'
import produce from 'immer'
/************************************************************************
 // 1. action types
 
 FETCH_POINTS.INDEX
 FETCH_POINTS.REQUEST
 FETCH_POINTS.SUCCESS
 FETCH_POINTS.FAIL
 NORMAL_ACTION
 ************************************************************************/
const prefix = '@APP/POINTS'

export const actionTypes = {
  FETCH_POINTS: makeAsyncActionsTypes(prefix, 'FETCH_POINTS'),
  CREATE_REQUEST: makeAsyncActionsTypes(prefix, 'CREATE_REQUEST'),
  FETCH_REQUESTS: makeAsyncActionsTypes(prefix, 'FETCH_REQUESTS'),

  FETCH_ONE_POINTS: makeAsyncActionsTypes(prefix, 'FETCH_ONE_POINTS'),
  CREATE_POINTS: makeAsyncActionsTypes(prefix, 'CREATE_POINTS'),
  UPDATE_POINTS: makeAsyncActionsTypes(prefix, 'UPDATE_POINTS'),
  DELETE_POINTS: makeAsyncActionsTypes(prefix, 'DELETE_POINTS'),
  CLOSE_MODAL: `${prefix}/CLOSE_MODAL`,
  OPEN_MODAL: `${prefix}/OPEN_MODAL`,
  REMOVE_ERROR: `${prefix}/REMOVE_ERROR`,
}

/************************************************************************
// 2. action creator

fetchPoints(payload)
fetchPoints.request(payload)
fetchPoints.success(payload)
fetchPoints.fail(payload)
resetUser(payload)
************************************************************************/
export const fetchPoints = makeAsyncActionCreator(actionTypes.FETCH_POINTS)
export const createRequest = makeAsyncActionCreator(actionTypes.CREATE_REQUEST)
export const fetchRequests = makeAsyncActionCreator(actionTypes.FETCH_REQUESTS)

export const fetchOnePoints = makeAsyncActionCreator(actionTypes.FETCH_ONE_POINTS)
export const createPoints = makeAsyncActionCreator(actionTypes.CREATE_POINTS)
export const updatePoints = makeAsyncActionCreator(actionTypes.UPDATE_POINTS)
export const deletePoints = makeAsyncActionCreator(actionTypes.DELETE_POINTS)
export const closeModal = makeActionCreator(actionTypes.CLOSE_MODAL)
export const openModal = makeActionCreator(actionTypes.OPEN_MODAL)
export const removeError = makeActionCreator(actionTypes.REMOVE_ERROR)
/***********************************************************************
// 3. reducer
error format
{
  status: 422,
  message: "입력값을 확인해주세요.",
  errors: [
    email: [{...}]
  ]
}

************************************************************************/
const initialModalState = {
  open: false,
  requesting: false,
  error: null,
  point: null,
}

const initialRequest = {
  requesting: false,
  error: null,
  success: false,
}

const initialRequests = {
  loading: false,
  requests: [],
  meta: {
    total: 0,
    perPage: 10,
    currentPage: 1,
  },
  search: {},
  pagination: { total: 0, per_page: 10, current_page: 1 },
  error: null,
}

const initialState = {
  loading: false,
  error: null,
  points: [],
  modal: initialModalState,
  meta: {
    total: 0,
    perPage: 10,
    currentPage: 1,
  },
  search: {},
  pagination: { total: 0, per_page: 10, current_page: 1 },
  request: initialRequest,
  requests: initialRequests,
}

const pointsReducer = function (state = initialState, action) {
  switch (action.type) {
    // FETCH_POINTS
    case actionTypes.FETCH_POINTS.INDEX:
    case actionTypes.FETCH_POINTS.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true
      })
    case actionTypes.FETCH_POINTS.SUCCESS:
      return {
        ...state,
        points: action.payload.points.data,
        meta: {
          total: action.payload.points.total,
          per_page: action.payload.points.per_page,
          current_page: action.payload.points.current_page,
          page: action.payload.points.current_page,
          ...action.payload.search,
        },
        pagination: {
          total: action.payload.points.total,
          per_page: action.payload.points.per_page,
          current_page: action.payload.points.current_page,
        },
        search: {
          ...action.payload.search,
        },
        loading: false,
      }
    case actionTypes.FETCH_POINTS.FAIL:
      return { ...state, loading: false, error: action.payload.error }

    // CREATE_REQUEST
    case actionTypes.CREATE_REQUEST.INDEX:
    case actionTypes.CREATE_REQUEST.REQUEST:
      return { ...state, request: { ...state.request, requesting: true, error: null } }
    case actionTypes.CREATE_REQUEST.SUCCESS:
      return {
        ...state,
        request: { ...state.request, requesting: false, success: true },
      }
    case actionTypes.CREATE_REQUEST.FAIL:
      return { ...state, request: { ...state.request, requesting: false, error: action.payload.error } }

    // FETCH_REQUESTS
    case actionTypes.FETCH_REQUESTS.INDEX:
    case actionTypes.FETCH_REQUESTS.REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          loading: true,
        },
      }
    case actionTypes.FETCH_REQUESTS.SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          requests: action.payload.requests.data,
          meta: {
            total: action.payload.requests.total,
            per_page: action.payload.requests.per_page,
            current_page: action.payload.requests.current_page,
            page: action.payload.requests.current_page,
            ...action.payload.search,
          },
          pagination: {
            total: action.payload.requests.total,
            per_page: action.payload.requests.per_page,
            current_page: action.payload.requests.current_page,
          },
          search: {
            ...action.payload.search,
          },
          loading: false,
        },
      }
    case actionTypes.FETCH_REQUESTS.FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          loading: false,
          error: action.payload.error,
        },
      }

    // FETCH_ONE_POINTS
    case actionTypes.FETCH_ONE_POINTS.INDEX:
    case actionTypes.FETCH_ONE_POINTS.REQUEST:
      return { ...state, modal: { ...state.modal, requesting: true } }
    case actionTypes.FETCH_ONE_POINTS.SUCCESS:
      return {
        ...state,
        modal: { ...state.modal, requesting: false, point: action.payload.point },
      }
    case actionTypes.FETCH_ONE_POINTS.FAIL:
      return { ...state, modal: { ...state.modal, requesting: false, error: action.payload.error } }

    // CREATE_POINTS
    case actionTypes.CREATE_POINTS.INDEX:
    case actionTypes.CREATE_POINTS.REQUEST:
      return { ...state, modal: { ...state.modal, open: true, requesting: true } }
    case actionTypes.CREATE_POINTS.SUCCESS:
      return { ...state, modal: { ...state.modal, requesting: false } }
    case actionTypes.CREATE_POINTS.FAIL:
      return {
        ...state,
        modal: { ...state.modal, requesting: false, error: action.payload.error },
      }

    // UPDATE_POINTS
    case actionTypes.UPDATE_POINTS.INDEX:
    case actionTypes.UPDATE_POINTS.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.UPDATE_POINTS.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false }, point: {} }
    case actionTypes.UPDATE_POINTS.FAIL:
      return { ...state, modal: { ...state.modal, loading: false, error: action.payload.error } }

    // DELETE_POINTS
    case actionTypes.DELETE_POINTS.INDEX:
    case actionTypes.DELETE_POINTS.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.DELETE_POINTS.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false } }
    case actionTypes.DELETE_POINTS.FAIL:
      return { ...state, modal: { ...state.modal, loading: false, error: action.payload.error } }

    // OPEN_MODAL
    case actionTypes.OPEN_MODAL:
      return { ...state, modal: { ...state.modal, open: true } }

    // CLOSE_MODAL
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        modal: initialModalState,
      }

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.modal.error.errors[action.payload.name] = undefined
        draft.modal.error.message = undefined
      })
    default:
      return state
  }
}

export default pointsReducer
