import request from 'lib/request'

const api = {
  fetch: ({ plusid_id, ct_id }) =>
    request.get(`/api/customers/points/${plusid_id}/${ct_id}`),
  add: (plusid_id, ct_id, point_id) =>
    request.post(`/api/customers/points/${plusid_id}/${ct_id}/add`, {
      point_id,
    }),
  point_request: (plusid_id, ct_id, amount) =>
    request.post(`/api/customers/points/${plusid_id}/${ct_id}/request`, {
      amount,
    }),
}

export default api
