import React, { useState } from 'react'
import { ConfigProvider, Form, Input, Select, Button, Space, DatePicker } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/ko'
import ko_KR from 'antd/lib/locale/ko_KR'

const dateFormat = 'YYYY-MM-DD'

const initialSearch = { state: '', username: '', mobile: '', from: '', to: '' }

const PointRequestSearch = ({ meta, handleSearch, pagination, handleExcelDownload, downloading, loading }) => {
  const [search, setSearch] = useState(initialSearch)
  const handleStateChange = (value) => setSearch({ ...search, state: value })
  const handleInputChange = (e) => setSearch({ ...search, [e.target.name]: e.target.value })
  const handleRangeChange = (dates, dateStrings) => {
    if (Array.isArray(dateStrings) && dateStrings.length === 2) {
      setSearch({ ...search, from: dateStrings[0], to: dateStrings[1] })
    }
  }

  const handleSubmit = () => {
    handleSearch({ ...search, currentPage: 1 })
  }

  const handleReset = () => {
    setSearch(initialSearch)
    handleSearch({ ...initialSearch, currentPage: 1 })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Form layout="inline" style={{ marginBottom: 16 }}>
          <Form.Item>
            <Input name="mobile" value={search.mobile} onChange={handleInputChange} style={{ width: 150 }} placeholder="휴대전화" />
          </Form.Item>
          <Form.Item>
            <Input name="username" value={search.username} onChange={handleInputChange} style={{ width: 150 }} placeholder="이름" />
          </Form.Item>
          <Form.Item>
            <Select name="state" value={search.state} onChange={handleStateChange} style={{ width: 150 }}>
              <Select.Option value="">= 전체 =</Select.Option>
              <Select.Option value="requesting">접수</Select.Option>
              <Select.Option value="fail">거절</Select.Option>
              <Select.Option value="success">지급</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <ConfigProvider locale={ko_KR}>
              <DatePicker.RangePicker format={dateFormat} onChange={handleRangeChange} value={[search.from && moment(search.from, dateFormat), search.to && moment(search.to, dateFormat)]} />
            </ConfigProvider>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={handleReset}>리셋</Button>
              <Button type="primary" onClick={handleSubmit}>
                검색
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      <div>
        <span style={{ fontWeight: 'bold', marginRight: 15 }}>
          <span style={{ color: 'gray' }}>Total: </span>
          {pagination && pagination.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
        <Button onClick={() => handleExcelDownload()} type="success" ghost style={{ marginBottom: 16 }} disabled={loading || downloading}>
          <FileExcelOutlined /> 엑셀
        </Button>
      </div>
    </div>
  )
}

export default PointRequestSearch
