import request from 'lib/request'
import axios from 'lib/request/axios'

const baseUrl = '/api/office'

const api = {
  fetch: (meta) => request.get(`${baseUrl}/points`, meta),
  fetchOne: (user) => request.get(`${baseUrl}/${user.id}`),
  create: (user) => request.post(baseUrl, user),
  update: (user) => request.put(`${baseUrl}/${user.id}`, user),
  delete: (user) => request.del(`${baseUrl}/${user.id}`),
  requestCreate: (inputs) => request.post(`${baseUrl}/request`, inputs),
  requestFetch: (meta) => request.post(`${baseUrl}/requests`, meta),
  requestUpdate: (data) => request.put(`${baseUrl}/requests/${data.id}`, data),
  // excel download
  all: (meta) =>
    axios({ method: 'get', url: `${baseUrl}/points/all`, params: meta }),
}

export default api
