import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import api from '../../api'
// import { setToken } from 'lib/token'
// import message from 'lib/message'
import {
  fetchRequests,
  updateRequest,
  fetchRequest,
  openModal,
  closeModal,
  removeError,
} from '../../store'

import Page from 'components/Common/Page'
import PointRequestList from '../../components/PointRequestList'
import PointRequestModal from '../../components/PointRequestModal'
import PointRequestSearch from '../../components/PointRequestSearch'

import xlsx from 'xlsx'
import { format } from 'date-fns'
import queryString from 'query-string'
import message from 'lib/message'

const PointsRequestListPage = (props) => {
  const { history, location } = props

  const dispatch = useDispatch()
  const loading = useSelector((store) => !!store.requests.loading)
  const requests = useSelector((store) => store.requests.requests)
  const pagination = useSelector((store) => store.requests.pagination)
  const meta = useSelector((store) => store.requests.meta)
  const modal = useSelector((store) => store.requests.modal)
  const errorMessage = useSelector(
    (store) =>
      store.requests.modal.error &&
      store.requests.modal.error.status === 400 &&
      store.requests.modal.error.message
  )
  const search = useSelector((store) => store.requests.search)

  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    load()
  }, [dispatch, location.search])

  const handleOpen = (request) => {
    request && dispatch(fetchRequest(request))
    dispatch(openModal())
  }

  const handleExit = () => {
    dispatch(fetchRequests())
  }

  const load = () => {
    const _query = queryString.parse(location.search)
    dispatch(fetchRequests(_query))
  }

  // const handlePageChange = (page) => dispatch(fetchRequests(omit({ ...meta, currentPage: page }, 'total')))

  const handlePageChange = (page) => {
    const _query = { ...queryString.parse(location.search), page: page }
    history.push(`/points/requests?${queryString.stringify(_query)}`)
  }

  const handleSearch = (_search) => {
    history.push(`/points/requests?${queryString.stringify(_search)}`)
  }

  const handleExcelDownload = () => {
    setDownloading(true)
    api
      .all(search)
      .then((response) => {
        const now = new Date()
        const timestamp = +now
        const filename =
          '적립금요청' + format(now, 'yyyymmdd') + timestamp + '.xlsx'
        // console.log(response.data)
        const data = convertToExcelData(response.data.requests)
        downloadExcel(data.requests, filename, data.header)
      })
      .catch((error) => {
        message.error(`다운로드에 실패했습니다.`)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  return (
    <Page>
      <PointRequestSearch
        meta={search}
        handleSearch={handleSearch}
        pagination={pagination}
        handleExcelDownload={handleExcelDownload}
        downloading={downloading}
        loading={loading}
      />
      <PointRequestList
        loading={loading}
        meta={meta}
        data={requests}
        handleOpen={handleOpen}
        handlePageChange={handlePageChange}
        downloading={downloading}
        pagination={{
          current: pagination.current_page,
          pageSize: pagination.per_page,
          total: pagination.total,
          onChange: handlePageChange,
          showSizeChanger: false,
        }}
        load={load}
      />
      <PointRequestModal
        requesting={modal.requesting}
        open={modal.open}
        request={modal.request}
        errors={modal.error && modal.error.errors}
        errorMessage={errorMessage}
        handleClose={() => dispatch(closeModal())}
        handleExit={handleExit}
        handleUpdate={(request) => dispatch(updateRequest(request))}
        removeError={(payload) => dispatch(removeError(payload))}
      />
    </Page>
  )
}

export default PointsRequestListPage

function convertToExcelData(requests) {
  const header = [
    '순번',
    '이름',
    '성별',
    '생년월일',
    '휴대폰번호',
    '카드번호',
    '우편번호',
    'E-mail',
    '주소',
    '신청일',
    '중간상태',
    '지불방법',
    '지급금액',
    '상세주소1',
    '상세주소2',
    '대리신청자정보',
    '심사상태',
    '반려사유',
  ]

  const arr = requests.map((request, index) => {
    return {
      no: index + 1,
      username: request.username,
      sex: '',
      jumin_num: request.jumin_num,
      mobile: request.mobile,
      card: '',
      postNum: '',
      email: '',
      address: '',
      createdAt: request.created_at
        ? format(new Date(request.created_at), 'yyyyMMdd')
        : '',
      state1: '',
      payType: '',
      request_point: request.request_point,
      addr1: '',
      addr2: '',
      deputy: '',
      state: '',
      return: '',
    }
  })

  return {
    header: header,
    requests: arr,
  }
}

function downloadExcel(arr, filename = 'Test.xlsx', header = false) {
  const ws = xlsx.utils.json_to_sheet(arr)
  const wb = xlsx.utils.book_new()
  if (header) {
    header.forEach((x, idx) => {
      const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 })
      ws[cellAdd].v = x
    })
  }
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')
  xlsx.writeFile(wb, filename)
}
