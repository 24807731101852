import React from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Layout, Menu, Button } from 'antd'
// import history from 'lib/history'
import logoImg from './wmy_logo.png'

import { PhoneFilled } from '@ant-design/icons'

const { SubMenu } = Menu

const { Header } = Layout

const getSubMenu = (route, user) => {
  // console.log(route)
  if (!route.sidebar) return null
  if (route.role === 'admin' && user.role !== 'admin') return null
  return (
    <SubMenu key={route.path} icon={route.icon} title={route.name}>
      {route.routes.map((sub) => getMenu(sub))}
    </SubMenu>
  )
}

const getMenu = (route, user) => {
  if (!route.sidebar) return null
  if (route.role && route.role.indexOf(user.role) < 0) return null
  // if (route.role === 'admin' && user.role !== 'admin') return null
  return (
    <Menu.Item key={route.path} icon={route.icon}>
      <Link to={route.path}>{route.name}</Link>
    </Menu.Item>
  )
}

const DefaultLayout = ({ user, children, handleLogout, routes, location }) => {
  let history = useHistory()
  if (!user) return <Redirect to="/login" />

  return (
    <Layout style={{ minHeight: '100vh', minWidth: 1400 }} id="default-layout">
      <Header>
        <div style={{ display: 'flex' }}>
          <div className="logo">
            {/* <img src={logoImg} style={{ height: 20 }} /> */}
            <span style={{ display: 'inline-block', color: 'yellow' }}>
              사또요 적립금 관리
            </span>
          </div>
          {/* <div className="logo">
            <img src={logoImg} alt="coopang eats" style={{ height: 20 }} />
          </div> */}
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[history.location.pathname]}
          >
            {routes.map((route) =>
              route.routes ? getSubMenu(route, user) : getMenu(route, user)
            )}
          </Menu>
        </div>
        <div className="account-action">
          <span>
            {user.user_name}({user.user_id})
          </span>
          <Button
            type="link"
            onClick={handleLogout}
            style={{ color: 'yellow' }}
          >
            로그아웃
          </Button>
        </div>
      </Header>
      <Layout className="site-layout">{children}</Layout>
    </Layout>
  )
}
export default DefaultLayout
