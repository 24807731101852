import { all } from 'redux-saga/effects'
import appSagas from './app/sagas'
import accountSagas from './account/sagas'
// import agenciesSagas from './agencies/sagas'

import app from './app/store'
import account from './account/store'
// import agencies from './agencies/store'

const reducers = {
  app,
  account,
  // agencies,
}

function* sagas() {
  yield all([appSagas(), accountSagas()])
}

export { reducers, sagas }
