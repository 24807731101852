import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects'
import { actionTypes } from './store'
import { fetchOffices, createOffices, fetchOneOffices, updateOffices, deleteOffices, closeModal } from './store'
import api from './api'
import apiSaga from 'lib/saga/apiSaga'
import message from 'lib/message'

const { FETCH_OFFICES, CREATE_OFFICES, FETCH_ONE_OFFICES, UPDATE_OFFICES, DELETE_OFFICES } = actionTypes

// 1. fetch
export function* fetchOfficesSaga(action) {
  yield call(apiSaga, api.fetch, fetchOffices, { apiPayload: action.payload })
}

// 2. create
export function* createOfficesSaga(action) {
  const success = yield call(apiSaga, api.create, createOffices, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('지자체 계정이 생성되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 3. fetchOne
export function* fetchOneOfficesSaga(action) {
  yield call(apiSaga, api.fetchOne, fetchOneOffices, { apiPayload: action.payload })
}

// 4. update
export function* updateOfficesSaga(action) {
  const success = yield call(apiSaga, api.update, updateOffices, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('지자체 계정이 수정되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 5. delete
export function* deleteOfficesSaga(action) {
  const success = yield call(apiSaga, api.delete, deleteOffices, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('지자체 계정이 삭제되었습니다.')
  } else {
    message.error('삭제중 에러가 발생했습니다.')
  }
}

export default function* officesSagas() {
  yield all([
    takeLatest(FETCH_OFFICES.INDEX, fetchOfficesSaga),
    takeLatest(CREATE_OFFICES.INDEX, createOfficesSaga),
    takeEvery(FETCH_ONE_OFFICES.INDEX, fetchOneOfficesSaga),
    takeLatest(UPDATE_OFFICES.INDEX, updateOfficesSaga),
    takeLatest(DELETE_OFFICES.INDEX, deleteOfficesSaga),
  ])
}
