import React, { useState } from 'react'
import api from '../api'
import { Button } from 'antd'
import message from 'lib/message'

const UnSavedPoint = ({ point, plusid_id, ct_id, reload }) => {
  const [requesting, setRequesting] = useState(false)

  const handleAdd = (point) => {
    setRequesting(true)

    setTimeout(() => {
      api
        .add(plusid_id, ct_id, point.id)
        .then((res) => {
          message.success('적립되었습니다.')
        })
        .catch((err) => {
          message.error(err.message)
        })
        .finally(() => {
          setTimeout(setRequesting(false), 500)
          reload()
        })
    }, 2000)
  }

  return (
    <div
      style={{
        fontSize: 18,
        padding: '15px 0',
        borderBottom: '1px solid lightgray',
        backgroundColor: '#ececec',
        marginBottom: 10,
        borderRadius: 10,
      }}
    >
      <div style={{ fontSize: 14, color: '#848484', textAlign: 'center' }}>
        {point.send_date}
      </div>
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
        {point.store_name}
      </div>
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <Button
          type="primary"
          onClick={() => handleAdd(point)}
          disabled={requesting}
        >
          {point.point.toLocaleString()}원 적립하기
        </Button>
      </div>
    </div>
  )
}

export default UnSavedPoint
