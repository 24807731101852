import { format } from 'date-fns'

const roles = {
  admin: '어드민',
  branche: '대행사',
  agency: '대리점',
  customer: '승인대기자',
}
// TODO : displayNumComma safari Error 해결하기
// export const displayNumComma = (num) => (num ? num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : '')
// export const displayNumComma = (x) => {
//   var parts = x.toString().split('.')
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//   return parts.join('.')
// }
export const displayJuminNum = (num) =>
  num
    ? num
        .toString()
        .replace(/-/g, '')
        .replace(/(\d{6})(\d{7})/g, '$1-$2')
    : ''
export const displayDate = (date) => (date ? format(new Date(date), 'yyyy.MM.dd') : '')
export const displayPhone = (phone) => (phone ? phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3') : '')
export const displayRole = (role) => (role ? roles[role] : '')
export const shorten = (str, max = null) => {
  if (!max || !str) return str
  return str.length > max + 3 ? str.substring(0, max) + '...' : str
}

const formatObj = {
  // comma: displayNumComma,
  jumin: displayJuminNum,
  date: displayDate,
  phone: displayPhone,
  role: displayRole,
  shorten,
}
export default formatObj
