import request from 'lib/request'

const baseUrl = '/api/office'

const accountApi = {
  getUser: () => request.get(`${baseUrl}/me`),
  login: ({ loginId, password, remember }) =>
    request.post(`${baseUrl}/login`, { loginId, password, remember }),
  // 현재 유저 비밀번호 변경
  password: (payload) => request.post('/api/account/password', payload),
}

export default accountApi
