import React from 'react'

import Privacy from '../components/PointRequestForm/Privacy'

const PrivacyPage = () => (
  <div style={{ margin: '16px 16px 16px' }}>
    <div style={{ margin: '0 auto', maxWidth: 500 }}>
      <h1>사또요 개인정보 처리방침</h1>
      <Privacy />
    </div>
  </div>
)
export default PrivacyPage
