import React, { useEffect, useState } from 'react'
import api from '../api'
import message from 'lib/message'
import queryString from 'query-string'
import { Button, Col, Row } from 'antd'

import CustomerPointList from '../components/CustomerPointList'
import CustomerPointAddForm from '../components/CustomerPointAddForm'

const requestMinimumPoint = 2000

const CustomerPointPage = ({ location }) => {
  // url에서 적립금 코드를 가져온다.
  const parsed = queryString.parse(location.search)
  const plusid_id = parsed.p
  const ct_id = parsed.u

  const [error, setError] = useState(false)
  const [points, setPoints] = useState([])
  const [unSavedPoints, setUnSavedPoints] = useState([])
  const [totalPoint, setTotalPoint] = useState(0)
  const [requesting, setRequesting] = useState(false)

  useEffect(() => {
    fetch()
    // console.log('code', code)
    // fetch({ page: 1 })
  }, [])

  const fetch = () => {
    if (!plusid_id || !ct_id) {
      message.error('잘못된 요청입니다.')
      // goHome()
      return false
    }

    api
      .fetch({ plusid_id, ct_id })
      .then((res) => {
        console.log(res)
        console.log(res.points)
        console.log(res.unSavedPoints)
        setPoints(res.points)
        setUnSavedPoints(res.unSavedPoints)
        setTotalPoint(res.total_points)
      })
      .catch((err) => {
        console.log(err)
        setError(err)
        message.error(error.message)
      })
  }

  // const { history } = props

  const handleSubmit = () => {
    setRequesting(true)
    api
      .point_request(plusid_id, ct_id, totalPoint)
      .then((res) => {
        message.success('적립금 송금이 요청되었습니다.')
      })
      .catch((err) => {
        console.log(err)
        message.error(err.message)
      })
      .finally(() => {
        fetch()
        setRequesting(false)
      })
  }

  const goApp = () => {
    window.location.href = process.env.REACT_APP_APP_URL
  }

  const saddoHomeUrl = process.env.REACT_APP_HOME_URL

  return (
    <div style={{ maxWidth: 600, margin: '0px auto' }}>
      <Row
        style={{
          padding: 10,
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        <Col flex="30px"></Col>
        <Col
          flex="auto"
          style={{
            textAlign: 'center',
            color: 'black',
            fontWeight: 'bold',
            fontSize: 22,
          }}
        >
          적립금
        </Col>
        <Col flex="30px" style={{ textAlign: 'right', marginTop: 4 }}>
          <a href={saddoHomeUrl} style={{ color: '#605f5f' }}>
            <svg
              style={{ width: 25, height: 25 }}
              ariaHidden="true"
              dataPrefix="fas"
              dataIcon="home"
              class="svg-inline--fa fa-home fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M488 312.7V456c0 13.3-10.7 24-24 24H348c-6.6 0-12-5.4-12-12V356c0-6.6-5.4-12-12-12h-72c-6.6 0-12 5.4-12 12v112c0 6.6-5.4 12-12 12H112c-13.3 0-24-10.7-24-24V312.7c0-3.6 1.6-7 4.4-9.3l188-154.8c4.4-3.6 10.8-3.6 15.3 0l188 154.8c2.7 2.3 4.3 5.7 4.3 9.3zm83.6-60.9L488 182.9V44.4c0-6.6-5.4-12-12-12h-56c-6.6 0-12 5.4-12 12V117l-89.5-73.7c-17.7-14.6-43.3-14.6-61 0L4.4 251.8c-5.1 4.2-5.8 11.8-1.6 16.9l25.5 31c4.2 5.1 11.8 5.8 16.9 1.6l235.2-193.7c4.4-3.6 10.8-3.6 15.3 0l235.2 193.7c5.1 4.2 12.7 3.5 16.9-1.6l25.5-31c4.2-5.2 3.4-12.7-1.7-16.9z"
              ></path>
            </svg>
          </a>
        </Col>
      </Row>
      <CustomerPointAddForm
        plusid_id={plusid_id}
        ct_id={ct_id}
        unSavedPoints={unSavedPoints}
        reload={fetch}
      />
      <div>
        <div
          style={{
            color: 'black',
            padding: '40px 15px',
            borderBottom: '5px solid lightgray',
            borderTop: '5px solid lightgray',
          }}
        >
          <div
            style={{ fontSize: 36, textAlign: 'center', fontWeight: 'bold' }}
          >
            보유적립금 {totalPoint.toLocaleString()}원
          </div>
          {/* <div style={{ fontSize: 16, textAlign: 'center' }}>
            {requestMinimumPoint.toLocaleString()}원 이상 사용 가능
          </div> */}
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            {/* <Button
              type="primary"
              disabled={totalPoint < requestMinimumPoint || requesting}
              onClick={handleSubmit}
            >
              {totalPoint.toLocaleString()}원 송금요청
            </Button> */}
            <div
              style={{
                fontSize: 16,
                textAlign: 'left',
                marginLeft: 15,
                marginTop: 30,
              }}
            >
              <div style={{ fontWeight: 'bold' }}>사또요 포인트 지급 안내</div>
              <br />
              {/* <div style={{ fontWeight: 'bold' }}>지급 조건</div>
              <br /> */}
              1. 동일 업체에서 주문한 경우에만 지급됩니다. (타 업체와 중복 사용
              불가)
              <br />
              2. 결제 영수증(현금영수증 또는 카드결제 영수증)을 첨부해 주세요.
              <br />
              3. 영수증 사진을 010-7168-8291로 보내주시면 확인 후 지급됩니다.
              <br />
              4. 지급받을 은행명과 계좌번호를 알려주세요.
              <br />
              5. 200포인트 지급 대상 업체는 위의 조건 없이 지급됩니다.
              <br />
              6. 3,000원 이상 신청가능합니다.
              <br />
              <br />※ 포인트는 현금 또는 상품권으로 지급 가능합니다.
            </div>
          </div>
        </div>
        <CustomerPointList
          plusid_id={plusid_id}
          ct_id={ct_id}
          points={points}
        />
      </div>
    </div>
  )
}

export default CustomerPointPage
