import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects'
import { actionTypes } from './store'
import { fetchPoints, createRequest, fetchRequests, createPoints, fetchOnePoints, updatePoints, deletePoints, closeModal } from './store'
import api from './api'
import apiSaga from 'lib/saga/apiSaga'
import message from 'lib/message'

const { FETCH_POINTS, CREATE_REQUEST, FETCH_REQUESTS, CREATE_POINTS, FETCH_ONE_POINTS, UPDATE_POINTS, DELETE_POINTS } = actionTypes

// 1. fetch
export function* fetchPointsSaga(action) {
  yield call(apiSaga, api.fetch, fetchPoints, { apiPayload: action.payload })
}

// 2. create
export function* createPointsSaga(action) {
  const success = yield call(apiSaga, api.create, createPoints, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('대리점이 생성되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 3. fetchOne
export function* fetchOnePointsSaga(action) {
  yield call(apiSaga, api.fetchOne, fetchOnePoints, { apiPayload: action.payload })
}

// 4. update
export function* updatePointsSaga(action) {
  const success = yield call(apiSaga, api.update, updatePoints, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('대리점이 수정되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 5. delete
export function* deletePointsSaga(action) {
  const success = yield call(apiSaga, api.delete, deletePoints, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('대리점이 삭제되었습니다.')
  } else {
    message.error('삭제중 에러가 발생했습니다.')
  }
}

// 6. request create
export function* createRequestSaga(action) {
  const success = yield call(apiSaga, api.requestCreate, createRequest, { apiPayload: action.payload })
  if (success) {
    yield put(closeModal())
    message.success('신청 되었습니다.')
    // 페이지 이동
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 7. request fetch
export function* fetchRequestsSaga(action) {
  yield call(apiSaga, api.requestFetch, fetchRequests, { apiPayload: action.payload })
}

export default function* pointsSagas() {
  yield all([
    takeLatest(FETCH_POINTS.INDEX, fetchPointsSaga),
    takeLatest(CREATE_REQUEST.INDEX, createRequestSaga),
    takeLatest(FETCH_REQUESTS.INDEX, fetchRequestsSaga),
    takeLatest(CREATE_POINTS.INDEX, createPointsSaga),
    takeEvery(FETCH_ONE_POINTS.INDEX, fetchOnePointsSaga),
    takeLatest(UPDATE_POINTS.INDEX, updatePointsSaga),
    takeLatest(DELETE_POINTS.INDEX, deletePointsSaga),
  ])
}
