import React from 'react'
import { Layout } from 'antd'
const { Content, Footer, Header } = Layout

const Page = ({ children, header, footer, contentMarginStyle }) => {
  const pageMargin = contentMarginStyle ? contentMarginStyle : { margin: '16px 16px 16px' }

  return (
    <>
      {header && <Header className="site-layout-background" style={{ padding: 0 }} />}
      <Content style={pageMargin}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 300 }}>
          {children}
        </div>
      </Content>
      {footer && <Footer style={{ textAlign: 'center' }}>Oin Design ©2020 Created by Oin</Footer>}
    </>
  )
}

export default Page
